/* global VeeValidate */
VeeValidate.defineRule('required', value => {
  if (!value) {
    return window.FORM_ERRORS.required
  }
  return true
})

VeeValidate.defineRule('email', value => {
  if (!value || !value.length) {
    return true
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return window.FORM_ERRORS.email
  }
  return true
})

VeeValidate.defineRule('min', (value, [limit]) => {
  if (!value || !value.length) {
    return true
  }
  if (value.length < limit) {
    return `Polje mora vsebovati vsaj ${limit} znakov`
  }
  return true
})

VeeValidate.defineRule('phone', (value) => {
  if (!value || !value.length) {
    return true
  }
  if (value.search(/^\d{7,20}$/) !== 0) {
    return window.FORM_ERRORS.phone
  }
  return true
})
