// eslint-disable-next-line no-unused-vars
function scrollIntoViewWithOffset (element, offset) {
  window.scrollTo({
    top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    behavior: 'smooth',
  })
}

function headerHeight () {
  return document.querySelector('header').getBoundingClientRect().bottom
}

function scrollIntoViewUnderHeader (element) {
  scrollIntoViewWithOffset(element, headerHeight())
}

export { scrollIntoViewWithOffset, headerHeight, scrollIntoViewUnderHeader }
