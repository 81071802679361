const urlGetParams = () => {
  const params = {}
  window.location.search.replace('?', '').split('&').forEach(item => {
    const split = item.split('=')
    if (split.length === 2) params[split[0]] = split[1]
  })
  return params
}

export { urlGetParams }
