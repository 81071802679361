import easingFunctions from './easing-functions'

const fade = {
  config: {
    object_selector: '.fade-out', // element(s)
    /* SETTINGS */
    hiding: true, // are you hidding element? false = fadeIn(), true = fadeOut()
    is_animated: true, // is opacity animated?
    duration: 600, // animation duration in ms
    easing: 'easeOutSine', // easing function which is used
    /* VERTICAL */
    vertical_movement: false,
    vertical_distance_px: 10,
    /* SWITCHING */
    switch_elements: false, // after animation completes, fadeIn another element? !!important!! if you set this to true, `hiding` setting will be automatically set to true
    // duration doubles if 'switch_elements' is set to true -> same duration is set for both elements
    switched_to_elements: '.fade-in', // element which is to show, after first is hidden
    /* CLASSES */
    hidden_class: 'hide', // class that is used for hidden element. After animation is complete, plugin cleans all set styles and adds this class. Without this class (set to null, false, '', ...), element will keep style attribute, which can cause unexpected behaviour
  },
  run: (settings) => {
    return new Promise((resolve, reject) => { // promise
      // settings
      const fadeSettings = Object.assign({}, fade.config, (settings && typeof settings === 'object' ? settings : {}))
      const isObjectCssSelector = typeof fadeSettings.object_selector === 'string'

      const elementsNr = isObjectCssSelector
        ? document.querySelectorAll(fadeSettings.object_selector).length
        : fadeSettings.object_selector.length
      let finalizedElements = 0

      if (fadeSettings.switch_elements && !fadeSettings.hiding) fadeSettings.hiding = true // rewrite wrong init
      const objectArray = isObjectCssSelector
        ? document.querySelectorAll(fadeSettings.object_selector)
        : fadeSettings.object_selector

      objectArray.forEach((item) => {
        if (fadeSettings.hiding) { // is element hiding
          fade.fadeElement(item, fadeSettings, 'hiding').then(() => {
            if (fadeSettings.switch_elements) { // after animation completes, fadeIn another element
              const fadeToElements = typeof fadeSettings.switched_to_elements === 'string'
                ? document.querySelectorAll(fadeSettings.switched_to_elements)
                : fadeSettings.switched_to_elements

              fadeToElements.forEach(el => {
                fade.fadeElement(el, fadeSettings).then(() => {
                  if (++finalizedElements === elementsNr) resolve(elementsNr) // finish
                })
              })
            } else {
              if (++finalizedElements === elementsNr) resolve(elementsNr) // finish
            }
          })
        } else { // element is showing - no switching
          fade.fadeElement(item, fadeSettings).then(() => {
            if (++finalizedElements === elementsNr) resolve(elementsNr)
          })
        }
      })
    })
  },
  fadeElement: (el, settings, isHiding) => { // el must be plain js element
    return new Promise((resolve, reject) => { // promise
      el.style.opacity = isHiding ? '1' : '0' // dbl check
      if (settings.hidden_class && !isHiding) el.classList.remove(settings.hidden_class) // if showing element, remove hidden class after opacity was set to 0

      if (!settings.is_animated) {
        el.style.opacity = isHiding ? '0' : '1'
        if (settings.hidden_class && isHiding) el.classList.add(settings.hidden_class)
        resolve(true)
      } else {
        // min time .1, max time 10 seconds
        const time = Math.max(0.1, Math.min(settings.duration / 1000, 10))
        let currentTime = 0
        const easing = easingFunctions.easingName(settings.easing)

        function fading () {
          currentTime += 1 / 60

          const p = currentTime / time

          if (p < 1) {
            const t = easingFunctions[easing](isHiding ? Math.abs(1 - p) : p)
            const rounded = (Math.ceil(t * 10000) / 10000) // round to 4 decimals
            el.style.opacity = rounded.toString()
            if (settings.vertical_movement) {
              el.style.transform = `translateY(${((-1 * settings.vertical_distance_px * rounded) + settings.vertical_distance_px)}px)`
            }
            requestAnimationFrame(fading) // next tick
          } else {
            el.style.opacity = isHiding ? '0' : '1'
            if (settings.hidden_class && isHiding) el.classList.add(settings.hidden_class)
            if (settings.hidden_class) el.removeAttribute('style')
            resolve(true)
          }
        }
        fading()
      }
    })
  },
}

export default fade
