/* eslint-disable no-unused-vars */
import { Swiper as SwiperJs, Navigation, Pagination, Scrollbar } from 'swiper'
import './helpers/vueValidationRules'

function initInfoCardSwiper (el) {
  const swiper = new SwiperJs(el.querySelector('.info-cards__slider'), {
    centeredSlides: false,
    grabCursor: true,
    loop: false,
    longSwipes: false,
    modules: [Scrollbar],
    slidesPerView: 1,
    scrollbar: {
      el: el.querySelector('.swiper-scrollbar'),
      draggable: true,
    },
    spaceBetween: 16,
    breakpoints: {
      1200: {
        slidesPerView: 2,
      },
      1750: {
        slidesPerView: 3,
      },
      2300: {
        slidesPerView: 'auto',
      },
    },
  })
}

function initProductsSwiper (el) {
  const swiper = new SwiperJs(el.querySelector('.product-slider'), {
    centeredSlides: false,
    grabCursor: true,
    loop: false,
    longSwipes: false,
    modules: [Navigation],
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 'auto',
      },
    },
    navigation: {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev'),
    },
    on: {
      init: () => {
        setTimeout(() => swiper.navigation.update(), 0)
      },
    },
  })
}

window.fillFeatures = (data) => {
  const texts = document.querySelectorAll('.hero-banner__left-column .features__box .features__text')
  data.forEach((text, index) => {
    if (texts[index]) texts[index].innerHTML = text
  })
}

;
import lozad from 'lozad'

function triggerAnimation (el) {
  el.classList.remove('fade-list-animate-tmp')
  el.classList.add('fade-list-animate')
  window.fadeLists()
}

function lazyLoad () {
  const els = document.querySelectorAll('.lazyload')
  const observer = lozad(els, {
    rootMargin: '10px 0px',
    threshold: 0,
    enableAutoReload: true,
    loaded: (el) => {
      const animatedDiv = el.closest('.fade-list-animate-tmp')
      if (animatedDiv) {
        if (el.complete) triggerAnimation(animatedDiv)
        else {
          el.onload = () => { triggerAnimation(animatedDiv) }
        }
      }
    },
  })
  observer.observe()
}

lazyLoad()

;
/* global gsap */
const videos = document.querySelectorAll('.video')

function video () {
  const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
  const body = document.querySelector('body')

  videos.forEach(v => {
    const fsButton = v.querySelector('.fullscreen-button')
    const exitFsBtn = v.parentElement.querySelector('.exit-fullscreen-btn')
    const videoEl = v.querySelector('video')

    videoEl.currentTime = 0.2
    videoEl.muted = true
    let anim = null
    let animVideoBorder = null

    if (fsButton && exitFsBtn) {
      const hero = document.querySelector('.hero-banner')
      const leftColumn = hero.querySelector('.hero-banner__left-column')
      const imageCircle = v.parentElement.parentElement
      let videoPlaying = isVideoPlaying(videoEl)

      fsButton.addEventListener('click', e => {
        imageCircle.style.left = '0px'
        imageCircle.style.width = `${imageCircle.getBoundingClientRect().width}px`
        hero.scrollIntoView({ block: 'end', behavior: 'smooth' })
        videoPlaying = isVideoPlaying(videoEl)
        if (!videoPlaying) {
          videoEl.currentTime = 0
          videoEl.play()
        }
        const buffer = window.innerWidth < 1200 ? 80 : 160
        anim = gsap.to(imageCircle, {
          left: -imageCircle.getBoundingClientRect().left + leftColumn.getBoundingClientRect().left,
          width: hero.getBoundingClientRect().width - 2 * leftColumn.getBoundingClientRect().left,
          borderRadius: 0,
          paddingTop: 20,
          height: window.innerHeight * 0.7,
          duration: 0.4,
          delay: Math.abs(Math.round((((hero.getBoundingClientRect().top - buffer) / 1900) + Number.EPSILON) * 100) / 100),
          ease: 'power2.out',
        })
        animVideoBorder = gsap.to(videoEl, {
          borderRadius: 0,
          duration: 0.4,
          delay: Math.abs(Math.round((((hero.getBoundingClientRect().top - buffer) / 1900) + Number.EPSILON) * 100) / 100),
          ease: 'power2.out',
        })

        gsap.to(exitFsBtn, {
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
        })

        gsap.to(fsButton, {
          opacity: 0,
          duration: 0.5,
          ease: 'power2.out',
        })
        body.classList.add('no-overflow')
      })

      exitFsBtn.addEventListener('click', e => {
        if (!videoPlaying) {
          videoEl.currentTime = 0.2
          videoEl.pause()
        }
        anim.reverse()
        animVideoBorder.reverse()
        gsap.to(exitFsBtn, {
          opacity: 0,
          duration: 0.7,
          delay: 0.2,
          ease: 'power2.out',
        })

        gsap.to(fsButton, {
          opacity: 1,
          duration: 0.7,
          delay: 0.2,
          ease: 'power2.out',
          onComplete: () => {
            imageCircle.removeAttribute('style')
          },
        })
        body.classList.remove('no-overflow')
      })
    }
  })
}

video()

;
/* global SwiperJs, Navigation, Pagination */
const galleries = document.querySelectorAll('.gallery')
function gallery () {
  galleries.forEach((gallery) => {
    const slider = gallery.querySelector('.swiper')
    if (!slider) { return }

    // eslint-disable-next-line no-unused-vars
    const swiper = new SwiperJs(slider, {
      slidesPerView: 'auto',
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: gallery.querySelector('.swiper-button-next'),
        prevEl: gallery.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: gallery.querySelector('.swiper-pagination'),
        type: 'bullets',
        bulletElement: 'div',
        clickable: true,
      },
    })
  })
}

gallery()

;
const faqSelector = document.querySelector('.faq-layout__theme-selector')

function faq () {
  if (faqSelector) {
    const allFaqModules = document.querySelectorAll('.faq-layout__accordions')
    document.querySelector('.faq-layout__accordions').classList.add('first-element')
    faqSelector.querySelector('select').addEventListener('change', e => {
      if (e.target.value === '') {
        allFaqModules.forEach(faq => {
          faq.classList.remove('is-hidden')
        })
        document.querySelector('.faq-layout__accordions.first-element').classList.remove('first-element')
        document.querySelector('.faq-layout__accordions').classList.add('first-element')
      } else {
        allFaqModules.forEach(faq => {
          faq.classList.toggle('is-hidden', !faq.classList.contains(e.target.value))
          faq.classList.toggle('first-element', faq.classList.contains(e.target.value))
        })
      }
    })
  }
}

faq()

;
const menuItems = document.querySelectorAll('.header-menu__link')

function mobileNavigation () {
  menuItems.forEach(item => {
    item.addEventListener('click', e => {
      const openedItem = document.querySelector('.header-menu__item.opened')
      item.parentElement.classList.toggle('opened')
      if (openedItem) openedItem.classList.remove('opened')
    })
  })
}

mobileNavigation()

;
/* global LOADER, initInfoCardSwiper, initProductsSwiper */

const eVozilaBannerElements = document.querySelectorAll('.e-vehicles-swiper')
async function eVozilaBanner () {
  if (!eVozilaBannerElements.length) return

  try {
    LOADER.show()
    const formatPrice = (price) => `${new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price)} EUR`

    const response = await fetch(`${window.API_URL}/api/tco-api/e-mobilnost/models-list`)
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const apiData = await response.json()

    eVozilaBannerElements.forEach(eVozilaBannerElement => {
      const slides = eVozilaBannerElement.querySelectorAll('.swiper-wrapper .swiper-slide')
      slides.forEach(slide => {
        const priceData = apiData.find((model) => model.brandCode === slide.dataset.brandCode && model.modelCode === slide.dataset.modelCode)
        if (!priceData) {
          slide.remove()
          // return
        } else {
          slide.querySelector('.price-from').innerHTML = formatPrice(priceData.priceMin)
        }
      })

      // shuffle order of vehicles
      const wrapper = eVozilaBannerElement.querySelector('.swiper-wrapper')
      if (wrapper) {
        for (let i = wrapper.children.length; i >= 0; i--) {
          wrapper.appendChild(wrapper.children[Math.random() * i | 0])
        }
      }

      eVozilaBannerElement.classList.contains('e-vozila-banner')
        ? initInfoCardSwiper(eVozilaBannerElement)
        : initProductsSwiper(eVozilaBannerElement)
    })
  } catch (error) {
    console.log('ooopss.. something went wrong: ', error)
  }
  LOADER.hide()
}
eVozilaBanner()

;
/* global initProductsSwiper */

const moonSolutionsBannerElement = document.querySelector('.moon-solutions-banner')
function moonSolutionsBanner () {
  initProductsSwiper(moonSolutionsBannerElement)
}
if (moonSolutionsBannerElement) moonSolutionsBanner()

;
import fade from './animation/fade'
import dispatchClick from './helpers/dispatch-click'
import { urlGetParams } from './helpers/url-get-params'

const contentGroupEl = document.querySelector('.content-group')

function contentGroupSetFilter (value) {
  document.querySelectorAll('.content-group__filter [data-value]').forEach(el => {
    const add = el.dataset.value === value
    el.classList.toggle('button--selected', add)
    const icon = el.querySelector('.icon')
    if (icon) icon.classList.toggle('icon-check', add)
  })
}

function contentGroupSwitch () {
  let isAnimationInProgress = false
  contentGroupEl.querySelectorAll('.content-group__filter [data-value]').forEach(filter => {
    filter.addEventListener('click', event => {
      const btn = event.target.closest('[data-value]')
      if (isAnimationInProgress || btn.classList.contains('button--selected')) return
      isAnimationInProgress = true

      const value = btn.dataset.value
      fade.run({
        object_selector: '[data-group]:not(.is-hidden)', // element(s)
        hiding: true,
        is_animated: true,
        duration: 300, // duration doubles if 'switch_elements' is set to true -> same duration is set for both elements
        easing: 'easeInSine',
        switch_elements: true,
        switched_to_elements: `[data-group="${value}"]`,
        hidden_class: 'is-hidden',
      }).then(() => {
        isAnimationInProgress = false
      })

      contentGroupSetFilter(value)
    })
  })

  const urlParams = urlGetParams()
  if (urlParams.group) {
    const el = document.querySelector(`.content-group__filter [data-value="${urlParams.group}"]`)
    if (el) {
      // no animation
      fade.run({
        object_selector: '[data-group]:not(.is-hidden)', // element(s)
        hiding: true,
        is_animated: false,
        switch_elements: true,
        switched_to_elements: `[data-group="${urlParams.group}"]`,
        hidden_class: 'is-hidden',
      })
      contentGroupSetFilter(urlParams.group)
    }
  }

  if (contentGroupEl.dataset.autoplay) {
    let counter = 0
    const buttons = contentGroupEl.querySelectorAll('.content-group__filter [data-value]')
    if (!buttons.length) return

    const interval = setInterval(() => {
      if (!buttons[++counter]) counter = 0
      dispatchClick(buttons[counter], false)
    }, 5000)

    document.querySelector('.content-group__filter').addEventListener('click', () => {
      clearInterval(interval)
    })
  }
}

if (contentGroupEl) contentGroupSwitch()

;
/* global initInfoCardSwiper */

const infoCardElements = document.querySelectorAll('.info-cards-default')

function infoCardsDefault () {
  infoCardElements.forEach(infoCard => {
    initInfoCardSwiper(infoCard)
  })
}
infoCardsDefault()

;
/* global SwiperJs, Navigation */

const userReviewsElement = document.querySelector('.user-reviews')
function userReviews () {
  // eslint-disable-next-line no-unused-vars
  const swiper = new SwiperJs('.user-reviews-slider', {
    loop: false,
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 60,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
}
if (userReviewsElement) userReviews()

;
import { scrollIntoViewUnderHeader } from './tools/scroll-into-view-with-offset'
import { urlGetParams as getParams } from './helpers/url-get-params'

const scrollToContentElement = document.querySelector('.scroll-to-content')

function scrollToContent () {
  scrollToContentElement.addEventListener('click', event => {
    const anchor = document.querySelector('.scroll-to-content-anchor')
    if (anchor) {
      scrollIntoViewUnderHeader(anchor)
    }
  })
}
if (scrollToContentElement) scrollToContent()

function scrollToUrlId () {
  const urlParams = getParams()
  if (urlParams.id) {
    const anchor = document.getElementById(urlParams.id)
    if (anchor) {
      scrollIntoViewUnderHeader(anchor)
    }
  }
}
scrollToUrlId()

;
/* global fade */

import { animateFeatureLine } from './features'

window.fadeLists = () => {
  document.querySelectorAll('.fade-list-animate').forEach(list => {
    const observer = new IntersectionObserver(entries => {
      if (!entries[0].isIntersecting || entries[0].target.classList.contains('list-animated')) return
      Array.from(list.children).forEach(child => { child.style.opacity = '0' }) // rewrite css
      entries[0].target.classList.add('list-animated')
      showElement(entries[0].target.children[0], entries[0].target.dataset)
    }, {
      rootMargin: '10px 0px',
      threshold: 0,
      enableAutoReload: true,
    })

    observer.observe(list)
  })
}

function showElement (el, dataset) {
  fade.run({
    object_selector: [el], // element(s)
    hiding: false,
    is_animated: true,
    duration: parseInt(dataset.duration || 400),
    easing: 'easeInSine',
    vertical_movement: true,
    vertical_distance_px: parseInt(dataset.verticalDistance || 15),
    hidden_class: 'is-hidden',
  }).then(() => {
    const nextEl = el.nextElementSibling
    animateFeatureLine(el)
    if (nextEl) showElement(nextEl, dataset)
  })
}

window.fadeLists()

;
function externalLinks () {
  Array.from(document.links).forEach((el) => {
    const link = el
    if (link.hostname && link.hostname !== window.location.hostname) {
      link.target = '_blank'
      link.res = 'noopener'
    }
  })
}

externalLinks()
